import React, { useState, useRef } from "react";
import styled from "styled-components";
import pix from "./Assets/dom.png";
import {CiLocationOn} from "react-icons/ci"
import {BsFillKeyFill, BsMicFill, BsFillClockFill} from "react-icons/bs"
import images from "./Assets/tecback.png";
import image4 from "../Assets/headerbg.png"
import images2 from "./Assets/hall.png";
import images3 from "./Assets/techme.png";
import {BsCalendarDate, BsDownload} from "react-icons/bs"
import {MdLocationPin} from "react-icons/md"
import {BiTimeFive} from "react-icons/bi"
// import NavComp from "./NavComp";
import html2canvas from "html2canvas";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";

const EnterData: React.FC = () => {
  const [image, setImage] = useState<string>(pix);
  const [name, setName] = useState<string>("");
  const [show, setShow] = useState<Boolean>(false);
  const [show2, setShow2] = useState<Boolean>(false);

  const Toggle = () => {
    setShow(!show)
  }
  const Toggle2 = () => {
    setShow2(!show2)
  }

  const displayPix = (e: any) => {
    const file = e.target.files[0];
    const save = URL.createObjectURL(file);
    setImage(save);
    console.log(save);
  };

  const printRef: any = React.useRef();

  const handleDownloadImage = async () => {
    const element = printRef.current;
    const canvas = await html2canvas(element);

    const data = canvas.toDataURL("image/jpg");
    const link = document.createElement("a");

    if (typeof link.download === "string") {
      link.href = data;
      link.download = "image.jpg";

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      window.open(data);
    }
  };

  return (
    <div>
      <Container ref={printRef}>
        <Top>Event Registration</Top>
        {/* <NavComp image={image} name={name} /> */}
        
          <ImageInfo>
            <Image src={image} />
            <ImageLabel htmlFor="pix">Enter your Image</ImageLabel>
            <ImageInput
              id="pix"
              type="file"
              accept="image/jpg, image/png, image/jpeg"
              onChange={displayPix}
            />
            <Hold>
              <NameH>
                <Name>Name</Name>
                <Inputs placeholder="Enter your name" type="text" value={name}
                onChange={(e: any) => {
              setName(e.target.value);
            }}/>
              </NameH>
              <NameH>
                <Name>PhoneNo</Name>
                <Inputs placeholder="Enter your phoneNo" type="text" />
              </NameH>
              <Holder>Your Name is: <strong> {name}</strong></Holder>
            </Hold>
          </ImageInfo>

          <Button onClick={Toggle2}>Submit</Button>
          {show2 ? (
            <Final>
                <Pre>
                    <Profilecard>
                        <Image src={image} />
                        <Hey>Hey There🤗🤗🤗</Hey>
                        <Will>I  <h2>{name}</h2> will be attending CodeLab Tech Conferrence</Will>
                        <Theme>Theme:</Theme>
                        <Tittle>Tech as a Leveller in the 21st Century </Tittle>
                        <DateHold>
                <Location><Circle2><CiLocationOn /></Circle2>
                    <Text>Ajif LGA</Text>
                </Location>
                <Location>
                    <Circle2><BsFillKeyFill /></Circle2>
                    <Text>500 Seats</Text>
                </Location>
                <Location>
                    <Circle2><BsMicFill /></Circle2>
                    <Text>4 Speakers</Text>
                </Location>
                <Location>
                    <Circle2><BsFillClockFill /></Circle2>
                    <Text>10Am</Text>
                </Location>
            </DateHold>
                        
                    </Profilecard>{" "}
                    {name !== "" && image !== pix ? (
                        <Download onClick={handleDownloadImage}>Download <Ic><BsDownload /></Ic></Download>
                    ) : null}
                </Pre>
            </Final>
          ) : null}

          {/* <Input
            placeholder="Enter your Name Please"
            value={name}
            onChange={(e: any) => {
              setName(e.target.value);
            }}
          />
          <br />
          <br />
          <br />
          <div>
            Your Name is: <strong> {name}</strong>
          </div>
          <br />
          <br /> */}
          
      </Container>
      
    </div>
  );
};

export default EnterData;
const Location = styled.div`
    width: 180px;
    height: 70px;
    display: flex;
    background-color: #D32F2F;
    align-items: center;
    margin: 10px;
    justify-content: center;
    padding-right: 7px;
    border-radius: 10px;
    @media screen and (max-width: 455px){
        width: 100%;
    }
     @media screen and (max-width: 351px){
        width: 80%;
    }
`
const Text = styled.div`
    color: white;
    margin-left: 10px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    text-align: center
`
const Circle2 = styled.div`
    width: 50px;
    height: 50px;
    border: 4px solid #e33737;
    border-radius: 50px;
    box-shadow: 0 0 0 1px #ce0202, 0 0 0 1px #ce0202;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 30px;
    @media screen and (max-width: 952px){
        width: 30px;
        height: 30px;
        font-size: 25px;
        margin-bottom: 5px;
    }
`

const Ic = styled.div`
    margin-left: 7px;
`
const Download = styled.div`
    width: 140px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000269;
    color: white;
    cursor: pointer;
    margin-top: 10px;
    border-radius: 4px;
    @media screen and (max-width: 506px){
    margin-top: 75px;
  }
`
const Ico2 = styled.div`
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
`
const Ico = styled.div`
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
`
const Dec = styled.h3`
    color: white;
    display: flex;
    align-items: center;
    margin-left: 13px;
`
/* const Circle2 = styled.div`
    width: 40px;
    height: 40px;
    margin-left: 8px;
    border-radius: 50px;
    background-color: #1294C3;
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 656px){
        margin-top: 12px;
    }
` */
const Circle = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 50px;
    background-color: #1294C3;
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 656px){
        margin-top: 12px;
    }
`
const Date = styled.div`
    width: 100%;
    /* height: 50%; */
    display: flex;
    align-items: center;
    margin-top: 20px;
`
const Tittle = styled.h3`
    margin: 0;
    font-size: 22px;
    text-align: center;
    background: -webkit-linear-gradient(#1a2980, #26d0ce);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
`
const Theme = styled.h2`
    color: white;
    margin: 0;
    text-align: center;
`
const DateHold = styled.div`
    width: 100%;
    height: 100%;
    overflow: hidden;
    /* background-color: #08213D; */
    margin-top: 60px;
    border-radius: 50px;
    padding-top: 20px;
       flex-wrap: wrap;
    padding-bottom: 20px;
    display: flex;
    justify-content: center;
    
    @media screen and (max-width: 952px){
        width: 650px;
    }
     @media screen and (max-width: 670px){
        width: 550px;
    }
    @media screen and (max-width: 568px){
        width: 450px;
    }
     @media screen and (max-width: 456px){
        width: 350px;
    }
     @media screen and (max-width: 360px){
        width: 300px;
    }
`
const Will = styled.h3`
    color: white;
    width: 80%;
    text-align: center;
    margin-top: -10px;
    h2{
        margin: 0;
    }
`
const Hey = styled.h2`
    color: white;
`
const Profilecard = styled.div`
    width: 60%;
    height: 520px;
    background-color: none;
    margin-top: 50px;
    border-radius: 7px;
    /* border: 1px solid #000269; */
    align-items: center;
    display: flex;
    padding-top: 20px;
    flex-direction: column;
    @media screen and (max-width: 444px){
        width: 300px;
    }
`
const Pre = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    /* align-items: center; */
    background-color: rgba(0, 0, 0, 0.6);
    flex-direction: column;
`
const Final = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url(${images2});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    flex-direction: column;
    background-color: #D74343;
`
const Prof = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
`
const Sub = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: green;
  background-image: url(${images});
  display: flex;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: fixed;
`
const Button = styled.div`
  padding: 10px 35px;
  background-color: #000269;
  color: white;
  font-size: 18px;
  margin-top: 20px;
  cursor: pointer;
  border-radius: 4px;
  text-decoration: none;
`
const Holder = styled.div`
  color: white;
  font-size: 20px;
`
const Inputs = styled.input`
  width: 320px;
  margin-left: 30px;
  padding-left: 10px;
  font-size: 18px;
  display: flex;
  align-items: center;
  height: 40px;
  outline: none;
  border: none;
  border-radius: 3px;
  @media screen and (max-width: 506px){
    width: 250px;
  }
`
const Name = styled.h3`
  color: white;
  margin: 0;
  /* margin-left: 35px; */
  /* width: 100%; */
  margin-bottom: 10px;
`
const NameH = styled.div`
  width: 100%;
  height: 90px;
  display: flex;
  /* background-color: red; */
  align-items: center;
  padding-bottom: 25px;
  flex-direction: column;
  align-items: center;
`

const Hold = styled.div`
  width: 440px;
  height: 300px;
  /* background-color: green; */
  border: 1px solid #182e58;
  margin-top: -300px;
  border-radius: 10px;
  display: flex ;
  flex-direction: column;
  padding-top: 30px;
  align-items: center;
  @media screen and (max-width: 506px){
    width: 300px;
  }
`

const Top = styled.h1`
  color: white;
`

const Input = styled.input`
  border: 1px solid #000269;
  height: 40px;
  padding-left: 10px;
  border-radius: 3px;
  outline: none;
  font-size: 18px;
  ::placeholder {
  }
`;

const ImageInput = styled.input`
  display: none;
`;

const ImageLabel = styled.label`
  background-color: #000269;
  color: white;
  padding: 15px 20px;
  border-radius: 5px;
  margin: 15px 0;
  margin-bottom: 330px;
  transition: all 360ms;
  :hover {
    transform: scale(0.98);
    cursor: pointer;
  }
`;

const Image = styled.img`
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: 2px solid #000269;
  object-fit: cover;
`;

const ImageInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  /* background-color: green; */
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
  justify-content: center;
  align-items: center;
  /* background-color: red; */
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: #081D42;
  display: flex;
  /* background-image: url(${images2}); */
  background-repeat: no-repeat;
  background-position: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 50px;
  position: relative;
`;