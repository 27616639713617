import React from 'react'
import Allroutes from './Components/Allroutes'
import Header from "./Components/Header"
import Hero from './Components/Hero/Hero'
import Homescreen from './Components/Homescreen'





const App = () => {
  return (
    <div>
      <Allroutes />
      <Homescreen />
      {/* <NewHero /> */}
    </div>
  )
}

export default App