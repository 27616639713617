import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import images from "../Assets/headerbg.png"
import images2 from "../Assets/texti.png"
import Header from '../Header'
import {CiLocationOn} from "react-icons/ci"
import {BsFillKeyFill, BsMicFill, BsFillClockFill} from "react-icons/bs"
import Conference from '../Conference'
import { NavLink } from 'react-router-dom'

const Hero = () => {
    const [days, setDays] = useState<any>("00")
    const [hours, setHours] = useState<any>("00")
    const [minutes, setMinutes] = useState<any>("00")
    const [seconds, setSeconds] = useState<any>("00")

    useEffect(() => {
        const target = new Date("12/16/2022 23:59:59")

        const interval = setInterval(() => {
            const now = new Date()
            const difference = target.getTime() - now.getTime()

            const d = Math.floor(difference / (1000 * 60 * 60 * 24))
            setDays(d)

            const h = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
            setHours(h)

            const m = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60))
            setMinutes(m)

            const s = Math.floor((difference % (1000 * 60)) / (1000))
            setSeconds(s)
        }, 1000)
        return () => clearInterval(interval)
    })
  return (
    <Container>
        <Header />
        <Card>
            <Wrapper>
                <Button>17 Dec, 2022</Button>
            <Bold>CodeLab Set06 Tech Conference</Bold>
            <Theme>Theme:</Theme>
            <Title>Tech as a Leveller in the 21st Century</Title>
            <CountDownHold>
                <Box>
                    <Num>{days}</Num>
                    <Days>Days</Days>
                </Box>
                <Box>
                    <Num>{hours}</Num>
                    <Days>Hour</Days>
                </Box>
                <Box>
                    <Num>{minutes}</Num>
                    <Days>Minutes</Days>
                </Box>
                <Box>
                    <Num>{seconds}</Num>
                    <Days>Seconds</Days>
                </Box>
            </CountDownHold>
            <DateHold>
                <Location><Circle><CiLocationOn /></Circle>
                    <Text>Ajif LGA</Text>
                </Location>
                <Location>
                    <Circle><BsFillKeyFill /></Circle>
                    <Text>500 Seats</Text>
                </Location>
                <Location>
                    <Circle><BsMicFill /></Circle>
                    <Text>4 Speakers</Text>
                </Location>
                <Location>
                    <Circle><BsFillClockFill /></Circle>
                    <Text>10Am</Text>
                </Location>
            </DateHold>
            </Wrapper>
            <Reg>
                <Res>
                    <Wraps>
                        <Gis>Be Quick and Register</Gis>
                    <Button2 to="/register">REGISTRATION</Button2>
                    </Wraps>
                </Res>
            </Reg>
            <Copy>Copyright © Code by Okwoli Godwin 2022 by Invitation | All Rights Reserved</Copy>
        </Card>
    </Container>
  )
}

export default Hero
const Copy = styled.div`
    color: #dbdbdb;
    line-height: 45px;
    text-align: center;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: 300
`
const Wraps = styled.div`
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex-wrap: wrap;
`
const Button2 = styled(NavLink)`
    background: #08213d none repeat scroll 0 0;
    border-radius: 35px;
    color: #fff;
    display: inline-block;
    line-height: 60px;
    text-align: center;
    width: 210px;
    font-size: 14px;
    font-weight: 700;
    margin-left: 20px;
    text-decoration: none;
    cursor: pointer;
`
const Gis = styled.div`
    line-height: 60px;
    font-weight: 300;
    font-size: 24px;
    color: #fff;
    font-family: 'Open Sans', sans-serif;
`
const Res = styled.div`
    /* line-height: 60px;
    font-weight: 300;
    font-size: 24px; */
    background-color: rgba(210, 63, 64, 0.9);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 10px;
    align-items: center;
    /* color: #fff;
    font-family: 'Open Sans', sans-serif; */
`
const Reg = styled.div`
    width: 100%;
    height: 100%;
    
    background-image: url(${images2});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    /* background-color: blue; */
    display: flex;
    justify-content: center;
    align-items: center;
`
const Wrapper = styled.div`
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`
const Text = styled.div`
    color: white;
    margin-left: 10px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    text-align: center
`
const Circle = styled.div`
    width: 50px;
    height: 50px;
    border: 4px solid #e33737;
    border-radius: 50px;
    box-shadow: 0 0 0 1px #ce0202, 0 0 0 1px #ce0202;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 30px;
    @media screen and (max-width: 952px){
        width: 30px;
        height: 30px;
        font-size: 25px;
        margin-bottom: 5px;
    }
`
const Location = styled.div`
    width: 180px;
    height: 70px;
    display: flex;
    background-color: #D32F2F;
    align-items: center;
    margin: 10px;
    justify-content: center;
    padding-right: 7px;
    border-radius: 10px;
    @media screen and (max-width: 455px){
        width: 100%;
    }
     @media screen and (max-width: 351px){
        width: 80%;
    }
`
const DateHold = styled.div`
    width: 100%;
    height: 100%;
    overflow: hidden;
    /* background-color: #08213D; */
    margin-top: 60px;
    border-radius: 50px;
    padding-top: 20px;
       flex-wrap: wrap;
    padding-bottom: 20px;
    display: flex;
    justify-content: center;
    
    @media screen and (max-width: 952px){
        width: 650px;
    }
     @media screen and (max-width: 670px){
        width: 550px;
    }
    @media screen and (max-width: 568px){
        width: 450px;
    }
     @media screen and (max-width: 456px){
        width: 350px;
    }
     @media screen and (max-width: 360px){
        width: 300px;
    }
`
const Days = styled.div`
    color: #7d7d7d;;
`
const Num = styled.h2`
    color: #D32F2F;
    font-size: 40px;
    margin: 0;
    @media screen and (max-width: 425px){
        font-size: 30px;
    }
    @media screen and (max-width: 375px){
        font-size: 29px;
    }
`
const Box = styled.div`
    color: #7d7d7d;
    height: 140px;
    width: 150px;
    border: 1px solid #7d7d7d;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
    margin: 7px;
    @media screen and (max-width: 425px){
        height: 120px;
        width: 120px;
    }
    @media screen and (max-width: 375px){
        height: 100px;
        width: 100px;
        border-radius: 15px;
    }
    
`
const CountDownHold = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    margin-top: 30px;
    justify-content: center;
    /* background-color: green; */
    flex-wrap: wrap;
`
const Title = styled.h2`
    color: #fff;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 35px;
    font-style: italic;
    background: -webkit-linear-gradient(#D32F2F, #fff, #D32F2F);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-align: center;
    margin: 0;
    @media screen and (max-width: 840px){
        font-size: 30px;
    }
    @media screen and (max-width: 397px){
        font-size: 26px;
    }
    @media screen and (max-width: 320px){
        font-size: 23px;
    }
`
const Theme = styled.h3`
    font-family: 'Montserrat', sans-serif;
    font-size: 25px;
    color: #fff;
    line-height: 30px;
`
const Bold = styled.h1`
    font-size: 40px;
    line-height: 30px;
    color: #fff;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    margin-top: 40px;
    text-align: center;
    @media screen and (max-width: 768px){
        font-size: 30px;
    }
    @media screen and (max-width: 320px){
        font-size: 25px;
    }
`
const Button = styled.div`
    border: 2px solid #fff;
    width: 160px;
    height: 45px;
    border-radius: 25px;
    text-align: center;
    color: #fff;
    font-family: 'Montserrat', sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
`

const Card = styled.div`
    width: 100%;
    height: 100%;
    background-color: rgba(16, 37, 59, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
        padding-top: 150px;
`

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    background-image: url(${images});
    flex-direction: column;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    /* justify-content: center; */
    align-items: center;
`