import React from 'react'
import Conference from './Conference'

const Homescreen = () => {
  return (
    <div>
        {/* <Conference /> */}
    </div>
  )
}

export default Homescreen